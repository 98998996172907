import React, { useEffect , useState } from "react";
import { state } from "../helpers/LiveViewHelper";
import { observer }  from "mobx-react-lite";
import styles from  "../css/liveview.module.css";
import { set } from "mobx";
const ConfigEditor = observer(({}) => {
    const [tooltipsArray, setTooltipsArray] = useState([]);
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");
    useEffect(() => {
        state.getConfig();
    }, []);
    useEffect(() => {
        if (state.generalConfig.initiated){
            //get all tooltips keys from state.generalConfig.tooltips
            const tooltips = Object.keys(state.generalConfig.tooltips);

            //create array of tooltips
            const tooltipsArray = tooltips.map((tooltip) => {
                return {data :state.generalConfig.tooltips[tooltip], key : tooltip};
            });
            setTooltipsArray(tooltipsArray);
        }
    }, [state.generalConfig]);
   
    const onTextboxFocus = (e) => {
         e.target.rows = 3;
    }
    const onTextboxBlur = (e) => {
        e.target.rows = 1;
    }

    const onTitleChange = (e, key) => {
        //update session state
        state.generalConfig.tooltips[key].title = e.target.value;
      
    }

    const onDescriptionChange = (e, key) => {
        //update session state
        state.generalConfig.tooltips[key].description = e.target.value;
    }


    const  onSave = async () => {
        const response = await state.saveConfig(user, password);
        setMsg(response.message);
        if (response.success){
            
        }
        else {
         
        }

        setTimeout(() => {
            setMsg("");
        }, 3000);
    }
    return <div className={styles.configEditor_outer}>
        <div className={styles.config_auth_container}>
                <input type="text" placeholder="Username" onChange={(e)=> setUser(e.target.value)} />
                <input type="password" placeholder="Password" onChange={(e)=> setPassword(e.target.value)}  />
        </div>
            <div className={styles.configEditor_inner}>
        {tooltipsArray.map((tooltip, index) => {
            return <div key={index} className={styles.ttEditBox} >
                <input type="text" value={tooltip.data.title} onChange={(e)=> onTitleChange(e, tooltip.key)} />
                <textarea onChange={(e)=>onDescriptionChange(e, tooltip.key)} onBlur={onTextboxBlur} onClick={onTextboxFocus} rows={1}>
                {tooltip.data.description} 
                </textarea>
            </div>
        })}

        <div className={styles.save_container}>
            <button onClick={onSave}>Save</button>
            <span>{msg}</span>
        </div>
        </div>
    </div>

});

export default ConfigEditor;