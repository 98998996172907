import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { state, LiveViewHelper } from "../helpers/LiveViewHelper";
import styles from "../css/liveview.module.css";
import LangSwitch from "./LangSwitch";
//import moment
import SvgIcon from "./common/SvgIcon";
import moment from "moment";
import DashboardTime from "./tooltips/ttDashboardTime";
import HtmlTooltip from "./common/HtmlTooltip";
import Disclaimer from "./tooltips/ttDisclaimer";
const Header = observer(({ isLoading }) => {
  const [currentTime, setCurrentTime] = useState("");
  useEffect(() => {
    if (state.merchantData) {
      let time = state.merchantData.timezone?.now;
      setCurrentTime(getFormatedTime(time));
    }
  }, [state.merchantData]);

  const stopPolling = () => {
    LiveViewHelper.doPolling = false;
  };

  const toggleCurrency = () => {
    state.toggleCurrency();
  };
  const getFormatedTime = (time) => {
    //return date like Auf 26, 2024 at 09:57 GMT +1 ,
    //GMT should be the original GMT from the given time
    const parsedDateTime = moment.parseZone(time);
    // Get the offset in minutes and convert it to hours, keeping the sign
    const offsetInHours = parsedDateTime.utcOffset() / 60;

    // Format the date-time string with GMT offset from the parsed date-time
    const formattedDateTime =
      parsedDateTime.format("MMM DD, YYYY [at] HH:mm ") +
      `GMT${offsetInHours >= 0 ? "+" : ""}${offsetInHours}`;

    return formattedDateTime.toString();

    //return moment(time).format('MMM DD, YYYY [at] HH:mm');
  };

  return (
    <div className={styles.headerContainer} data-show={!isLoading}>
      <div className={styles.lv_header_left}>
        <div className={styles.header_left_title}>
          <span className={styles.lv_header}>
            {" "}
           Live View <span style={{fontWeight:"500"}}>{state.merchantData && state.merchantData.merchantName}</span>
          </span>
          <div className={styles.lv_header_sub}>
            <HtmlTooltip
              title={
                <Disclaimer offset={state?.merchantData?.timezone?.gmtOffset} />
              }
              placement="bottom"
              enterTouchDelay={50}
            >
              <span>
                <SvgIcon
                  paths={[
                    "M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5zm-1 6h2v2h-2zm0 4h2v6h-2z",
                  ]}
                  fill="#7C7C7C"
                  width={24}
                  height={24}
                  vbWidth={24}
                  vbHeight={24}
                />
              </span>
            </HtmlTooltip>
          </div>
          <span className={styles.beta_box}>BETA 1.0</span>
        </div>
        <div className={styles.header_left_bottomrow}>
          <div className={styles.lv_header_clock}>
            <HtmlTooltip
              title={
                <DashboardTime
                  offset={state?.merchantData?.timezone?.gmtOffset}
                />
              }
              placement="bottom"
              enterTouchDelay={50}
            >
              <span>
                <SvgIcon
                  paths={[
                    "M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z",
                    "M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0",
                  ]}
                  fill="#7C7C7C"
                  width={17}
                  height={17}
                  vbWidth={16}
                  vbHeight={16}
                />
              </span>
            </HtmlTooltip>
          </div>
          <div className={styles.lv_header_time}>{currentTime}</div>
        </div>
      </div>
      <div>
        <input
          data-show={false}
          type="button"
          value={"Toggle Currency"}
          onClick={toggleCurrency}
        />
        <input
          data-show={false}
          type="button"
          value={"Stop Polling"}
          onClick={stopPolling}
        />

        <LangSwitch />
      </div>
    </div>
  );
});

export default Header;
