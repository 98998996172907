import { useEffect } from "react";
import styles from "../css/liveview.module.css";
import SvgIcon from "./common/SvgIcon";
function Products({ products }) {
  useEffect(() => {}, [products]);

  const getFormattedNumber = (number) => {
    if (number) {
      return new Intl.NumberFormat("en-US").format(number);
    }
  };
  return (
    <div className={styles.products_container}>
      <div className={styles.products_container_internal}>
        <div className={styles.bar_chart_title}>
          <SvgIcon
            paths={[
              "m22 9.24-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28z",
            ]}
            fill="#7C7C7C"
            className={styles.tropy_icon}
            width={20}
            height={20}
            vbWidth={24}
            vbHeight={24}
          />
          Top Sold Products
        </div>
        <div className={styles.products_list}>
          <div
            data-show={products && products.length == 0}
            className={styles.products_no_data}
          >
            NO DATA
          </div>
          {products &&
            products.map((product, index) => {
              return (
                <div key={index} className={styles.product_item}>
                  <div title={product.product} className={styles.product_name}>
                    {/* <SvgIcon
                      paths={[
                        "M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z",
                      ]}
                      fill="#7C7C7C"
                      className={styles.tropy_icon}
                      width={16}
                      height={16}
                      vbWidth={16}
                      vbHeight={16}
                    /> */}
                    <span>{product.product}</span>
                  </div>
                  <div className={styles.product_quantity}>
                    {getFormattedNumber(product.quantity)} units
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Products;
