import React, { Fragment, useState,useEffect } from "react";
import Typography from "@mui/material/Typography";
import { state } from "../../helpers/LiveViewHelper";
import { observer }  from "mobx-react-lite";
const TrxAmount = observer(({ offset }) => {
  const [ttTitle,setTtTitle] = useState("");
  const [ttDescription,setTtDescription] = useState("");
  useEffect(() => {
        if (state.generalConfig.initiated){
        
          const {title, description} = state.generalConfig.tooltips.TrxAmount;   
          setTtTitle(title);       
          setTtDescription(description.replace(/{offset}/g, offset));
          
        }
    }, [state.generalConfig]);  
  return <Fragment>
    <Typography color="inherit">{ttTitle}</Typography>

    {ttDescription}
  </Fragment>
});

export default TrxAmount;
