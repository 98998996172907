import Styles from "../../css/liveview.module.css";
import { useEffect } from "react";
import { state } from "../../helpers/LiveViewHelper";
import { observer } from "mobx-react-lite";
import BarGraphRow from "./barGrapRow";

// const data = {
//   values: [19786, 13400, 5890],
//   labels: ["Canada", "United States", "United Kingdom"],
//   title: "Transactions by Country",
//   format: {
//     type: "currency",
//     currency: "USD",
//   },
// };

const valueFormatter = (value) => `${value}mm`;
const BarGraph = observer(({ data, icon }) => {
  useEffect(() => {}, []);

  const getPercentage = (value) => {
    return (value / Math.max(...data.values)) * 100 + "%";
  };

  const formatValue = (value) => {
    return state.formatAmount(value, data.format.type);
  };
  return (
    <div className={Styles.bar_chart_container}>
      <div className={Styles.bar_chart_inner_container}>
        <div className={Styles.bar_chart_title}>
          <i className={`bi ${icon} ${Styles.title_icon}`}></i>
          {data.title}
        </div>
        <div className={Styles.bar_chart_rows}>
          <div
            data-show={data.labels.length == 0}
            className={Styles.bar_chart_no_data}
          >
            NO DATA
          </div>
          {data.labels.map((label, index) => {
            return (
              <BarGraphRow
                value={formatValue(data.values[index])}
                label={label}
                percentage={getPercentage(data.values[index])}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default BarGraph;
