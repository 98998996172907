import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="right" {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    padding: theme.spacing(2),    
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.3)",
    border: "1px solid #dadde9",
    zIndex: 9999,
  },
}));

export default HtmlTooltip;
