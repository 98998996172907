import { useEffect, useState } from "react";
import { state, LiveViewHelper } from "../helpers/LiveViewHelper";
import { observer } from "mobx-react-lite";
import styles from "../css/liveview.module.css";
const LastOrder = observer(() => {
  //latestOrderInfo is an object with the following properties: countryName, amount,city
  const [latestOrderInfo, setLatestOrderInfo] = useState({});
  const [amount, setAmount] = useState(0);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (!state.merchantData || !state.merchantData.data.daily.latestOrder)
      return;
    setLatestOrderInfo(state.merchantData.data.daily.latestOrder);
    setAmount(
      state.formatAmount(state.merchantData.data.daily.latestOrder.amount)
    );

    if (state.merchantData.data.daily.latestOrder.amount > 0) {
      setShow(true);
    }
  }, [state.merchantData, state.currenctCurrencyInfo]);

  return (
    <div className={styles.last_order_container} data-show={show}>
      The last purchase was placed for a total of {amount} and will be shipped
      to {latestOrderInfo?.city}, {latestOrderInfo?.countryName}.
    </div>
  );
});

export default LastOrder;
