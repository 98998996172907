import { observer } from "mobx-react-lite";
import styles from "../css/liveview.module.css";
import { state } from "../helpers/LiveViewHelper";
import DataBox from "./common/dataBox";
import { useEffect, useState } from "react";
import GlobeSphere from "./Globe";
import BarGraph from "./common/barGraph";
import LineGraph from "./common/lineGraph";
import Products from "./products";
const LiveViewDashboard = observer(({ isLoading }) => {
  const [keysArray, setKeysArray] = useState([]);
  const [trxAmountByCountry, setTrxAmountByCountry] = useState(
    state.getTransactionAmountByCountryData()
  );
  const [trxCountByCountry, setTrxCountByCountry] = useState(
    state.getTransactionCountByCountryData()
  );

  useEffect(() => {
    if (!state.merchantData) return;
    setKeysArray(state.getKPIsInfo());
    setTrxAmountByCountry(state.getTransactionAmountByCountryData());
    setTrxCountByCountry(state.getTransactionCountByCountryData());
  }, [state.merchantData, state.currenctCurrencyInfo]);

  const createDataBoxes = () => {
    return keysArray.map((data, index) => {
      return (
        <DataBox
          key={index}
          iconPaths={data.iconPaths}
          title={data.title}
          value={data.value}
          tooltipComponent={data.tooltipComponent}
        />
      );
    });
  };
  return !isLoading ? (
    <div className={styles.dashboard_root}>
      <div className={styles.live_view_dashboard_container}>
        <div className={styles.lv_dash_left}>{createDataBoxes()}</div>
        <div className={styles.lv_dash_right} id="globeContainer">
          <GlobeSphere />
        </div>
      </div>
      <div className={styles.dashboard_bars_container}>
        <BarGraph data={trxAmountByCountry} icon={""} />
        <BarGraph data={trxCountByCountry} icon={""} />
        <Products products={state?.getProducts()} />
      </div>
      <div className={styles.dashboard_linecharts_container}>
      <LineGraph
          icon={""}
          iconPath={[
            "M18 6.5H16C16 4.29 14.21 2.5 12 2.5C9.79 2.5 8 4.29 8 6.5H6C4.9 6.5 4 7.4 4 8.5V20.5C4 21.6 4.9 22.5 6 22.5H18C19.1 22.5 20 21.6 20 20.5V8.5C20 7.4 19.1 6.5 18 6.5ZM12 4.5C13.1 4.5 14 5.4 14 6.5H10C10 5.4 10.9 4.5 12 4.5ZM18 20.5H6V8.5H8V10.5C8 11.05 8.45 11.5 9 11.5C9.55 11.5 10 11.05 10 10.5V8.5H14V10.5C14 11.05 14.45 11.5 15 11.5C15.55 11.5 16 11.05 16 10.5V8.5H18V20.5Z",
          ]}
          title={"Transactions Amount"}
          data={state?.merchantData?.data?.daily?.graph?.amounts}
          format={{
            type: "currency",
            currency: state?.merchantData?.currencyInfo.code,
          }}
        />        
        <LineGraph
          icon={""}
          iconPath={[
            "M6 14C5.44772 14 5 14.4477 5 15V16C5 16.5523 5.44772 17 6 17H7C7.55228 17 8 16.5523 8 16V15C8 14.4477 7.55228 14 7 14H6Z",
            "M4 4H20C21.11 4 22 4.89 22 6V18C22 19.11 21.11 20 20 20H4C2.89 20 2 19.11 2 18L2.01 6C2.01 4.89 2.89 4 4 4ZM5 18H19C19.55 18 20 17.55 20 17V11H4V17C4 17.55 4.45 18 5 18ZM4 8H20V6H4V8Z",
          ]}
          title={"Transactions Count"}
          data={state?.merchantData?.data?.daily?.graph?.transactions}
          format={{ type: "number" }}
        />
      </div>
    </div>
  ) : null;
});

export default LiveViewDashboard;
