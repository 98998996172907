
import './App.css';
import Container from './components/Demo/Container';
import LiveView from './components/LiveView';
import ConfigEditor from './components/configEditor';
//use router to navigate between pages
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  return <Router>
    <Routes>
      <Route path="/" element={<Container />} />
      <Route path="/liveview" element={<LiveView />} />
      <Route path="/config" element={<ConfigEditor />} />
    </Routes>
  </Router>
}

export default App;
