import React, { Fragment, useEffect,useState } from "react";
import Typography from "@mui/material/Typography";
import { state } from "../../helpers/LiveViewHelper";
import { observer }  from "mobx-react-lite";
const NowOnCheckout = observer(() => {
  const [ttTitle,setTtTitle] = useState("");
const [ttDescription,setTtDescription] = useState("");
useEffect(() => {
      if (state.generalConfig.initiated){
        const {title, description} = state.generalConfig.tooltips.NowOnCheckout;   
        setTtTitle(title);       
        setTtDescription(description);
        
      }
  }, [state.generalConfig]);


  return <Fragment>
    <Typography color="inherit">{ttTitle}</Typography>
    {ttDescription}
  </Fragment>
});

export default NowOnCheckout;
